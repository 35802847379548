import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Modal from 'react-modal';

import { routes } from 'utils/routes';
import { Image, Link } from 'components';

const TeamMember = ({ bloc, className }) => {
  const [showDialog, setShowDialog] = useState(false);
  const open = () => setShowDialog(true);
  const close = () => setShowDialog(false);
  const { specialty, spotlight, spotlightDescription, teamMember: { teamMember } } = bloc;
  const { photo, photoAlt, name, role, roleSpecialty, profile, nameSpecialty, hasAppointment } = teamMember;

  useEffect(() => {
    /* prevent body scroll when lightbox is open */
    if (showDialog) {
      document.documentElement.classList.add('html-no-scroll');
    }

    return () => {
      document.documentElement.classList.remove('html-no-scroll');
    };
  });

  const renderModal = () => {
    return (
      <Modal isOpen={showDialog} onRequestClose={close} className='modal-wrapper'>
        <button onClick={close} className='btn-closemodal'><Image image={'icons/icon_closemodal.svg'} /></button>
        <div className='modal-content'>
          <h2 className='modal-title'>Perfil</h2>
          <div className='profile-wrapper'>
            <Image image={photo || 'icons/icon_team_member.svg'} alt={photoAlt || ''} className='photo-wrapper' />
            <div className='info-wrapper'>
              <div>
                <h3 className='mb-10'>{specialty ? nameSpecialty || name : name}</h3>
                <p>{specialty ? roleSpecialty || '' : role}</p>
              </div>
              <div className='info-profile' dangerouslySetInnerHTML={{ __html: profile }} />
              {!!hasAppointment && <Link {...routes.appointment} className='button'>Marque a sua consulta</Link>}
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <div className={cx('team-member-wrapper', { spotlight }, [className])}>
      <Image image={photo || 'icons/icon_team_member.svg'} alt={photoAlt || ''} className='photo-wrapper' />
      <div className='info-wrapper'>
        <p className='name'>{specialty ? nameSpecialty || name : name}</p>
        <div>
          <p>{specialty ? roleSpecialty || '' : role}</p>
          {!spotlight &&
            <button onClick={open} className='btn-profile'><Image image={'icons/icon_profile.svg'} /></button>
          }
        </div>
        {spotlight &&
          <>
            <div className={cx({ 'mt-30': spotlight })} dangerouslySetInnerHTML={{ __html: spotlightDescription }} />
            <button onClick={open} className='btn-profile'><Image image={'icons/icon_profile.svg'} />Ver perfil</button>
          </>
        }
      </div>
      {showDialog && renderModal()}
    </div>
  );
};

TeamMember.propTypes = {
  bloc: PropTypes.object.isRequired,
  className: PropTypes.string,
};

TeamMember.defaultProps = {
  className: '',
};

export default TeamMember;
