import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from 'react-helmet';
import CookieConsent from 'react-cookie-consent';
import Cookies from 'js-cookie';

import Menu from './Menu';

const Header = ({ siteTitle }) => {
  const { data } = useStaticQuery(query);
  const [hasConsent, setConsent] = useState(Cookies.get('ClinicasLeiteCookie') || false);
  const tracking = data.analytics.siteOptions;

  const addGTAG = () => {
    return (
      <Helmet>
        {/* Global site tag (gtag.js) - Google Analytics */}
        <script async src={`https://www.googletagmanager.com/gtag/js?id=${tracking.googleAnalyticsId}`}></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${tracking.googleAnalyticsId}');
          `}
        </script>
      </Helmet>
    );
  };

  return (
    <header className='header-menu'>
      {hasConsent && addGTAG()}
      <CookieConsent
        disableStyles
        location='bottom'
        buttonText='Compreendo'
        cookieName='ClinicasLeiteCookie'
        containerClasses='cookie-consent-wrapper'
        buttonClasses='btn-consent'
        expires={150}
        onAccept={() => setConsent(true)}
      >
        {tracking.consentMessage}
      </CookieConsent>
      <Menu />
    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: '',
};

export default Header;

const query = graphql`
  query AnalyticsQuery {
    data {
      analytics: siteOptions {
        siteOptions {
          googleAnalyticsId
          consentMessage
        }
      }
    }
  }
`;
