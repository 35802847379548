import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Image, Link, Slideshow } from 'components';

const MagazineGroup = ({ bloc, className }) => {
  const { magazines } = bloc;
  const options = {
    infinite: magazines.length > 3,
    responsive: [
      {
        breakpoint: 750,
        settings: {
          infinite: magazines.length > 2,
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
    ],
  };

  return (
    <Slideshow slidesPerPage={3} options={options} className={cx('magazine-group-wrapper', [className])}>
      {magazines.map(({ magazine: { magazine: { file, preview, title } } }, idx) => (
        <div key={idx} className='magazine-wrapper'>
          <Image image={preview} alt={`Capa da revista ${title}`} className='photo-wrapper' />
          <div className='magazine-cta-wrapper'>
            <p className='title'>{title}</p>
            <Link to={file.mediaItemUrl} external newTab className='button'>Fazer Download</Link>
          </div>
        </div>
      ))}
    </Slideshow>
  );
};

MagazineGroup.propTypes = {
  bloc: PropTypes.object.isRequired,
  className: PropTypes.string,
};

MagazineGroup.defaultProps = {
  className: '',
};

export default MagazineGroup;
