import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { CallToAction, Slideshow } from 'components';
import { isSamePath } from 'utils/helpers';

const LinkedPages = ({ bloc, className }) => {
  const [width, setWidth] = useState(0);
  const { pages } = bloc.linkedPage.linkedPage;
  const findInfo = (content) => content.find(c => c.blocs.find(bloc => bloc.title));
  const Element = width < 1000 ? Slideshow : Fragment;

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return (
    <div className={cx('linked-pages-wrapper', [className])}>
      <Element>
        {pages.map(({ page }, idx) => {
          const info = findInfo(page.pageBuilder.content);
          if (!info || isSamePath(page.uri)) return null;
          const { title, quote } = info.blocs[0];
          const blocLink = {
            text: 'Saiba mais',
            page: { uri: page.uri },
          };

          return (
            <div key={idx} className='info-wrapper'>
              <h3>{title}</h3>
              <p>{quote}</p>
              <CallToAction bloc={blocLink} />
            </div>
          )
        })}
      </Element>
    </div>
  );
};

LinkedPages.propTypes = {
  bloc: PropTypes.object.isRequired,
  className: PropTypes.string,
};

LinkedPages.defaultProps = {
  className: '',
};

export default LinkedPages;
