import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const AwardsGroup = ({ bloc, className }) => {
  const { awards } = bloc;

  return (
    <div className={cx('awards-group-wrapper', [className])}>
      {awards.map((awardEntry) => {
        const { awardId, award: { title, text, logo, logoSecondary }} = awardEntry.award;
        const bgLogo = logo.imageFile.childImageSharp.fluid.src;
        const bgLogoSec = logoSecondary && logoSecondary.imageFile.childImageSharp.fluid.src;

        return (
          <div key={awardId} className='award-wrapper'>
            <div className='logo-wrapper' style={{ backgroundImage: `url(${bgLogo})` }} />
            {bgLogoSec && <div className='logo-wrapper logo-secondary' style={{ backgroundImage: `url(${bgLogoSec})` }} />}
            <h3>{title}</h3>
            <div dangerouslySetInnerHTML={{ __html: text }} />
          </div>
        );
      })}
    </div>
  );
};

AwardsGroup.propTypes = {
  bloc: PropTypes.object.isRequired,
  className: PropTypes.string,
};

AwardsGroup.defaultProps = {
  className: '',
};

export default AwardsGroup;
