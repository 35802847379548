import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Mark from 'mark.js';

import { Link } from 'components';

const Cover = ({ bloc, className }) => {
  const [markInstance, setMarkInstance] = useState(null);
  const [searching, setSearching] = useState(false);
  const [results, setResults] = useState(-1);
  const [keyword, setKeyword] = useState('');
  const { author, buttonTarget, buttonText, quote, showButton, showHelp, showSearch, strapline, title } = bloc;

  const clearSearch = () => {
    setSearching(false);
    clearActiveAccordions();
    setKeyword('');
    markInstance.map((instance) => instance.unmark());
    document.documentElement.classList.remove('is-searching');
    document.documentElement.classList.remove('no-results');
  };

  const clearActiveAccordions = () => {
    // reset
    [].slice.call(document.querySelectorAll('.accordion-group')).map((el) => el.classList.remove('active'));
    [].slice.call(document.querySelectorAll('.accordion-wrapper')).map((el) => el.classList.remove('active'));
    [].slice.call(document.querySelectorAll('.btn-accordion')).map((el) => el.classList.remove('active'));
    [].slice.call(document.querySelectorAll('.panel-accordion')).map((el) => el.classList.remove('active'));
  };

  const handleInput = (word) => {
    setKeyword(word);
  }

  const handleSearch = (ev) => {
    ev.stopPropagation();
    setSearching(true);
    document.documentElement.classList.add('is-searching');

    const options = {
      separateWordSearch: false,
      diacritics: true,
    };
    markInstance.map((instance) => {
      instance.unmark({
        done: () => {
          instance.mark(keyword, options);
          const totalResults = document.querySelectorAll('mark').length;
          (totalResults === 0) ? document.documentElement.classList.add('no-results') : document.documentElement.classList.remove('no-results');
          setResults(totalResults);
          handleAccordions();
        },
      });
    });
  };

  const handleAccordions = () => {
    // reset
    clearActiveAccordions();
    // add active to accordions with highlight
    [].slice.call(document.querySelectorAll('.panel-accordion mark')).map((el) => {
      const panel = el.closest('.panel-accordion');
      if (panel) {
        panel.classList.add('active');
        panel.parentNode.classList.add('active');
        panel.parentNode.parentNode.classList.add('active');
        panel.parentNode.querySelector('.btn-accordion').classList.add('active');
      }
    });
    [].slice.call(document.querySelectorAll('.btn-accordion mark')).map((el) => {
      const button = el.closest('.btn-accordion');
      if (button) {
        button.classList.add('active');
        button.parentNode.classList.add('active');
        button.parentNode.parentNode.classList.add('active');
      }
    });
  };

  useEffect(() => {
    if (!markInstance) {
      const elements = [].slice.call(document.querySelectorAll('.accordion-group')).map((el) => new Mark(el));
      setMarkInstance(elements);
    }

    return () => {
      document.documentElement.classList.remove('is-searching');
      document.documentElement.classList.remove('no-results');
    };
  }, []);

  return (
    <div className={cx('cover-wrapper fvw', [className], [bloc.className])}>
      <header>
        {strapline && <p className='strapline'>{strapline}</p>}
        <h1 dangerouslySetInnerHTML={{ __html: title }} />
        {quote &&
          <blockquote>
            {quote}
            {author && <footer>- {author}</footer>}
          </blockquote>
        }
        {showButton && <Link to={buttonTarget.uri} className='button'>{buttonText}</Link>}
        {showSearch &&
          <div className='input-wrapper input-search'>
            <input type='search' name='keyword' placeholder='Procure por palavra chave...' value={keyword} onChange={(ev) => handleInput(ev.target.value)} />
            {keyword.length > 0 &&
              <>
                <button className='btn-search' onClick={handleSearch}>pesquisar</button>
                <button className='btn-clear-search' onClick={clearSearch} type='reset' />
              </>
            }
            {searching && <p className='small mt-10'>{results} {`Resultado${results !== 1 ? 's' : ''}`}</p>}
          </div>
        }
        {showHelp &&
          <Link to='#contacts-sos' className='btn-help'>Precisa de ajuda?</Link>
        }
      </header>
    </div>
  );
};

Cover.propTypes = {
  bloc: PropTypes.object.isRequired,
  className: PropTypes.string,
};

Cover.defaultProps = {
  className: '',
};

export default Cover;
