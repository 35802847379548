import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'components';

const JumpTab = ({ bloc, className }) => {
  const { name, target } = bloc;

  return <Link anchor to={target} className='jump-tab'>{name}</Link>;
};

JumpTab.propTypes = {
  bloc: PropTypes.object.isRequired,
  className: PropTypes.string,
};

JumpTab.defaultProps = {
  className: '',
};

export default JumpTab;
