import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import cx from 'classnames';

import { Image, Link } from 'components';
import { isEmail } from 'utils/helpers';

const Footer = ({ className }) => {
  const { data } = useStaticQuery(query);
  const info = data.mainInfo.siteOptions;
  const social = data.social.siteOptions;
  const footer = data.footer.siteOptions;
  const lae = data.lae.siteOptions;

  return (
    <>
      <footer className={cx('bg-footer', [className])}>
        <div className='footer-wrapper'>
          <div className='footer-contacts links'>
            <Image image={'logo_footer.png'} alt='Clinicas Leite logo' className='footer-logo' />
            <div className='copyright'>&copy; {new Date().getFullYear()}, {info.copyright}</div>
            <div className='footer-social'>
              {social.youtube &&
                <Link to={social.youtube} external newTab>
                  <Image image={'icons/icon_youtube.svg'} alt={'Logo do Youtube'} />
                </Link>
              }
              {social.facebook &&
                <Link to={social.facebook} external newTab>
                  <Image image={'icons/icon_facebook.svg'} alt={'Logo do Facebook'} />
                </Link>
              }
              {social.instagram &&
                <Link to={social.instagram} external newTab>
                  <Image image={'icons/icon_instagram.svg'} alt={'Logo do Instagram'} />
                </Link>
              }
              {social.linkedin &&
                <Link to={social.linkedin} external newTab>
                  <Image image={'icons/icon_linkedin.svg'} alt={'Logo do LinkedIn'} />
                </Link>
              }
              {social.twitter &&
                <Link to={social.twitter} external newTab>
                  <Image image={'icons/icon_twitter.svg'} alt={'Logo do Twitter'} />
                </Link>
              }
            </div>
            {footer.contacts && footer.contacts.map(({ title, contact }, idx) => {
              const prefix = isEmail(contact) ? 'mailto' : 'tel';
              return (
                <div key={idx} className='mb-20'>
                  {title && <div className='contact-title'>{title}</div>}
                  <Link to={`${prefix}:${contact}`} external className={cx('ib', { 'mb-10': !title })}>{contact}</Link>
                </div>
              );
            })}
            <span className="small">CHAMADAS PARA A REDE FIXA NACIONAL.</span>
          </div>
          <div className='footer-links'>
            <h3>{footer.helpLinksTitle}</h3>
            <ul className='links mb-50'>
              {footer.helpLinks && footer.helpLinks.map(({ title, external, url, page }, idx) => (
                <li key={idx} className='mb-20'>
                  <Link external={external} to={external ? url : page ? page.uri : '/#'}>{title}</Link>
                </li>
              ))}
            </ul>
            <h3>{footer.siteLinksTitle}</h3>
            <ul className='links mb-50'>
              {footer.siteLinks && footer.siteLinks.map(({ title, external, url, page }, idx) => (
                <li key={idx} className='mb-20'>
                  <Link external={external} to={external ? url : page ? page.uri : '/#'}>{title}</Link>
                </li>
              ))}
            </ul>
          </div>
          <div className='footer-addresses'>
            <h3>{footer.addressesTitle}</h3>
            {footer.addresses && footer.addresses.map(({ address }, idx) => (
              <p key={idx} dangerouslySetInnerHTML={{ __html: address }} />
            ))}
          </div>
        </div>
      </footer>
      <div className='associated-logos-wrapper'>
        {lae.enterprises && lae.enterprises.map(({ className, url, logoAlt, logo }, idx) => (
          <Link key={idx} to={url} external newTab className={`link-logo ${className}`}>
            <Image image={logo} alt={logoAlt} imgStyle={{ objectFit: 'contain' }} />
          </Link>
        ))}
      </div>
    </>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
};

Footer.defaultProps = {
  className: '',
};

export default Footer;

const query = graphql`
  query FooterQuery {
    data {
      mainInfo: siteOptions {
        siteOptions {
          author
          copyright
          description
          keywords
          siteName
          socialImage {
            mediaItemUrl
          }
        }
      }
      social: siteOptions {
        siteOptions {
          facebook
          twitter
          youtube
          linkedin
          instagram
        }
      }
      footer: siteOptions {
        siteOptions {
          contacts {
            contact
            title
          }
          helpLinksTitle
          helpLinks {
            title
            external
            url
            page {
              ... on WPGraphQL_Page {
                uri
              }
            }
          }
          siteLinksTitle
          siteLinks {
            title
            external
            url
            page {
              ... on WPGraphQL_Page {
                uri
              }
            }
          }
          addressesTitle
          addresses {
            address
          }
        }
      }
      lae: siteOptions {
        siteOptions {
          enterprises {
            className
            url
            logoAlt
            logo {
              mediaItemUrl
              sourceUrl
              mediaItemId
              modified
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 400) {
                    ...FluidImageFragment
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
