import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import { Input } from 'components';

const FormShort = ({ className }) => {
  const { data } = useStaticQuery(query);
  const clinics = data.appointment.siteOptions.clinics;
  const specialties = data.appointment.siteOptions.specialties;
  const optionsClinics = clinics.map(({ name }) => ({ value: name, label: name }));
  const optionsSpecialties = specialties.map(({ name }) => ({ value: name, label: name }));

  return (
    <div className={className}>
      <div className='form-cols'>
        <Input id='appt-name' name='appt-name' label='Nome:' placeholder='escreva o seu nome' type='text' />
        <Input id='appt-email' name='appt-email' label='Email: *' placeholder='escreva o seu email' type='email' required />
      </div>
      <Input id='appt-specialty' name='appt-specialty' label='Especialidade:' placeholder='procurar por especialidade' type='dropdown' options={optionsSpecialties} />
      <div className='form-cols'>
        <Input id='appt-city' name='appt-city' label='Cidade:' placeholder='procurar por cidade' type='dropdown' options={optionsClinics} />
        <Input id='appt-date' name='appt-date' label='Data:' placeholder='dd-mm-aaaa' type='text' />
      </div>
    </div>
  );
};

FormShort.propTypes = {
  className: PropTypes.string.isRequired,
};

FormShort.defaultProps = {
  className: '',
};

export default FormShort;

const query = graphql`
  query FormShortQuery {
    data {
      appointment: siteOptions {
        ...AppointmentFormFragment
      }
    }
  }
`;
