import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Modal from 'react-modal';

import { Image, Link } from 'components';

const Press = ({ bloc, className }) => {
  const [showDialog, setShowDialog] = useState(false);
  const open = () => setShowDialog(true);
  const close = () => setShowDialog(false);
  const { spotlight, press: { press } } = bloc;
  const { date, newsType, linkToVideo, linkToExternal, photo, photoAlt, tags, text, textModal, title } = press;
  const isExternal = newsType === 'is_external';
  const isVideo = newsType === 'is_video';
  const isClipping = newsType === 'is_clipping';
  const localeDate = new Date(`${date.split('GMT')[0]}T00:00`).toLocaleDateString('pt-PT', { year: 'numeric', month: 'long', day: '2-digit' });
  const Element = isVideo ? Link : 'div';
  const elementProps = isVideo ? { to: linkToVideo || '', external: isVideo, newTab: isVideo } : {};

  useEffect(() => {
    /* prevent body scroll when lightbox is open */
    if (showDialog) {
      document.documentElement.classList.add('html-no-scroll');
    }

    return () => {
      document.documentElement.classList.remove('html-no-scroll');
    };
  });

  const renderModal = () => {
    return (
      <Modal isOpen={showDialog} onRequestClose={close} className='modal-wrapper'>
        <button onClick={close} className='btn-closemodal'><Image image={'icons/icon_closemodal.svg'} /></button>
        <div className='modal-content'>
          <h4 className='modal-title'>{title}</h4>
          <div className='press-wrapper'>
            <Link to={photo.imageFile.childImageSharp.fluid.src} newTab external className='press-clipping'>
              <Image image={photo} alt={photoAlt || ''} className='photo-wrapper' />
            </Link>
            <div className='info-wrapper'>
              <div className='tags-wrapper'>{tags[0].name}</div>
              <div className='date'>{localeDate}</div>
              <div className='info-press' dangerouslySetInnerHTML={{ __html: textModal }} />
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <div className={cx('press-wrapper', { spotlight }, { 'not-spotlight': !spotlight }, [className])}>
      <Element {...elementProps} className={cx('press-image', { 'cta-image': isVideo })}>
        <Image image={photo} alt={photoAlt || ''} className='photo-wrapper' />
        {spotlight && <h4 className='title'>{title}</h4>}
      </Element>
      {!spotlight &&
        <>
          <div className='press-content'>
            <div className='tags-wrapper'>{tags[0].name}</div>
            <div className='date'>{localeDate}</div>
            <h4 className='title'>{title}</h4>
          </div>
          <p dangerouslySetInnerHTML={{ __html: text }} />
          {isVideo && <Link to={linkToVideo} external newTab className='btn-see-more'>Ver notícia</Link>}
          {isExternal && <Link to={linkToExternal} external newTab className='btn-see-more'>Ver notícia</Link>}
          {isClipping && <button onClick={open} className='btn-see-more'>Ler mais</button>}
        </>
      }
      {showDialog && renderModal()}
    </div>
  );
};

Press.propTypes = {
  bloc: PropTypes.object.isRequired,
  className: PropTypes.string,
};

Press.defaultProps = {
  className: '',
};

export default Press;
