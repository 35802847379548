import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Press } from 'components';

const PressGroup = ({ bloc, className }) => {
  const [open, setOpen] = useState(false);
  const { news } = bloc;
  const cut = news.slice(0, 7).filter(n => n.spotlight).length < 1 ? 6 : 7;
  const visibleNews = open ? news : news.slice(0, cut);

  return (
    <div className={cx('press-group-wrapper', [className])}>
      {visibleNews.map((newsPress, idx) => <Press key={idx} bloc={newsPress} />)}
      {!open && news.length > cut && <button onClick={setOpen}>Ver todas</button>}
    </div>
  );
};

PressGroup.propTypes = {
  bloc: PropTypes.object.isRequired,
  className: PropTypes.string,
};

PressGroup.defaultProps = {
  className: '',
};

export default PressGroup;
