import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Image } from 'components';

const Picture = ({ bloc, className }) => {
  const { image, imageAlt } = bloc;

  return (
    <div className={cx('picture-wrapper', [className])}>
      <Image image={image} alt={imageAlt || ''} />
    </div>
  );
};

Picture.propTypes = {
  bloc: PropTypes.object.isRequired,
  className: PropTypes.string,
};

Picture.defaultProps = {
  className: '',
};

export default Picture;
