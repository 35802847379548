import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import cx from 'classnames';

import { Image, Link } from 'components';
import { createLocalLink } from 'utils/helpers';
import { routes } from 'utils/routes';

const Menu = ({ className }) => {
  const [active, toggleMenu] = useState(false);
  const { data } = useStaticQuery(query);
  const menu = data.menus.nodes[0].menuItems.nodes;
  const cmsUrl = data.generalSettings.url;

  return (
    <nav className={cx('nav-wrapper', [className])}>
      <button onClick={() => toggleMenu(!active)} className='burgermenu'>Open menu</button>
      <div className={cx('nav-menu', { active })}>
        {active &&
          <button onClick={() => toggleMenu(!active)} className='closemenu'>Close menu</button>
        }
        <Link {...routes.home} className='nav-logo'>
          <Image image={'logo_header.png'} alt='Clinicas Leite logo' />
        </Link>
        <div className='menu-wrapper'>
          {menu.map(({ menuItemId, label, url }) => {
            const path = createLocalLink(url, cmsUrl);
            return (
              <Link key={menuItemId} to={path}>{label}</Link>
            )
          })}
        </div>
      </div>
    </nav>
  );
};

Menu.propTypes = {
  className: PropTypes.string,
};

Menu.defaultProps = {
  className: '',
};

export default Menu;

const query = graphql`
  query MenuQuery {
    data {
      menus(where: {slug: "main-menu"}) {
        nodes {
          menuItems {
            nodes {
              menuItemId
              label
              url
            }
          }
        }
      }
      generalSettings {
        url
      }
    }
  }
`;
