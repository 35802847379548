import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import { Input } from 'components';

const FormFull = ({ className }) => {
  const { data } = useStaticQuery(query);
  const clinics = data.appointment.siteOptions.clinics;
  const specialties = data.appointment.siteOptions.specialties;
  const insurance = data.appointment.siteOptions.insurance;
  const optionsClinics = clinics.map(({ name }) => ({ value: name, label: name }));
  const optionsSpecialties = specialties.map(({ name }) => ({ value: name, label: name }));
  const optionsInsurance = insurance.map(({ name }) => ({ value: name, label: name }));

  return (
    <div className={className}>
      <Input id='appt-name' name='appt-name' label='Nome:' placeholder='escreva o seu nome' type='text' />
      <div className='form-cols'>
        <Input id='appt-email' name='appt-email' label='Email: *' placeholder='escreva o seu email' type='email' required />
        <Input id='appt-phone' name='appt-phone' label='Telefone:' placeholder='escreva o seu telefone' type='tel' />
      </div>
      <Input id='appt-specialty' name='appt-specialty' label='Especialidade:' placeholder='procurar por especialidade' type='dropdown' options={optionsSpecialties} />
      <div className='form-cols'>
        <Input id='appt-city' name='appt-city' label='Cidade:' placeholder='procurar por cidade' type='dropdown' options={optionsClinics} />
        <Input id='appt-date' name='appt-date' label='Data:' placeholder='dd-mm-aaaa' type='text' />
      </div>
      <Input id='appt-insurance' name='appt-insurance' label='Sistema/Seguro de saúde:' placeholder='procurar por sistema/seguro de saúde' type='dropdown' options={optionsInsurance} />
      <Input id='appt-obs' name='appt-obs' label='Observações:' placeholder='escreva aqui as suas observações' type='textarea' />
    </div>
  );
};

FormFull.propTypes = {
  className: PropTypes.string.isRequired,
};

FormFull.defaultProps = {
  className: '',
};

export default FormFull;

const query = graphql`
  query FormFullQuery {
    data {
      appointment: siteOptions {
        ...AppointmentFormFragment
      }
    }
  }
`;
