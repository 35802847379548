export function isEmail(email) {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
}


export function createLocalLink(url, cmsUrl) {
  return url.replace(cmsUrl, '');
}


export function isSamePath(newPath) {
  if (typeof window === 'undefined') return false; // because window is not defined on build
  return newPath.replace(/^\/|\/$/g, '') === window.location.pathname.replace(/^\/|\/$/g, '');
}
