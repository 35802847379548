import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const Accordion = ({ bloc, className }) => {
  const [active, toggleAccordion] = useState(false);
  const { title, hasText, text } = bloc;

  return (
    <div className={cx('accordion-wrapper', { active }, [className])}>
      <h4 className={cx('btn-accordion', { active })}>
        {hasText && text
          ? <button onClick={() => toggleAccordion(!active)}>
              {title}
            </button>
          : <>{title}</>
        }
      </h4>
      {hasText && text &&
        <div dangerouslySetInnerHTML={{ __html: text }} className={cx('panel-accordion', { active })} />
      }
    </div>
  );
};

Accordion.propTypes = {
  bloc: PropTypes.object.isRequired,
  className: PropTypes.string,
};

Accordion.defaultProps = {
  className: '',
};

export default Accordion;
