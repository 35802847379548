import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Link } from 'components';

const ServicesGroup = ({ bloc, className }) => {
  const { services } = bloc;

  return (
    <div className={cx('services-group-wrapper', [className])}>
      {services.map((service, idx) => {
        if (!service.page) return null;
        const { text, page: { title, uri } } = service;
        return <Link key={idx} to={uri} className='cta-service'>{text || title}</Link>;
      })}
    </div>
  );
};

ServicesGroup.propTypes = {
  bloc: PropTypes.object.isRequired,
  className: PropTypes.string,
};

ServicesGroup.defaultProps = {
  className: '',
};

export default ServicesGroup;
