import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const Paragraph = ({ bloc, className }) => {
  const { align, text } = bloc;

  return (
    <div className={cx('paragraph-wrapper', [className])}>
      <div className={`t-${align}`} dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  );
};

Paragraph.propTypes = {
  bloc: PropTypes.object.isRequired,
  className: PropTypes.string,
};

Paragraph.defaultProps = {
  className: '',
};

export default Paragraph;
