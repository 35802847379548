import React from 'react';
import PropTypes from 'prop-types';
import { Link as GatsbyLink } from 'gatsby';

const Link = ({ to, external, newTab, anchor, ...props }) => {
  const target = newTab ? '_blank' : '_self';

  if (anchor) return <a href={`#${to}`} {...props}>{props.children}</a>;

  return external
    ? <a href={to} target={target} rel='noopener noreferrer' {...props}>{props.children}</a>
    : <GatsbyLink {...props} to={to} />;
};

Link.propTypes = {
  to: PropTypes.string.isRequired,
  external: PropTypes.bool,
  newTab: PropTypes.bool,
  anchor: PropTypes.bool,
};

Link.defaultProps = {
  external: false,
  newTab: false,
  anchor: false,
};

export default Link;
