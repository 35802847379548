import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Image, Link } from 'components';
import { routes } from 'utils/routes';
import FormFull from './FormFull';
import FormShort from './FormShort';

const formFeedback = [
  'A enviar a sua marcação...',
  'Sucesso.',
  'Ups, aconteceu algo:',
];

const ContactForm = ({ bloc, className }) => {
  const [formStatus, handleFormStatus] = useState(-1);
  const [formError, handleFormError] = useState(null);
  const sending = () => handleFormStatus(0);
  const success = () => handleFormStatus(1);
  const failed = () => handleFormStatus(2);
  const { form, formId } = bloc;

  const handleSubmit = (ev) => {
    ev.preventDefault();
    handleFormError(null);
    sending();
    // todo: update url
    const url = `${process.env.GATSBY_API_URL}/wp-json/contact-form-7/v1/contact-forms/${formId}/feedback`;
    const data = new FormData(ev.target);

    fetch(url, {
      method: 'POST',
      body: data,
      credentials: 'same-origin',
      // credentials: 'omit',
    }).then(function(response) {
      // response.status     //=> number 100–599
      // response.statusText //=> String
      // response.headers    //=> Headers
      // response.url        //=> String
      success();
      // return response.text();
    }, function(error) {
      failed();
      handleFormError(error.message);
    });
  };

  return (
    <>
      { formStatus === 1
        ? <div className={cx('booking-success', { 'booking-wrapper': form === 'short' })}>
            <Image image='icons/icon_form_success.svg' />
            <h2>Será contactado/a brevemente para confirmação da consulta que solicitou.<br/><b className='mt-30 ib'>Obrigado.</b></h2>
            <button onClick={() => handleFormStatus(-1)}>Confirmar</button>
          </div>
        : <div className={cx('booking-wrapper', [className])}>
            <h3>Marcar Consulta</h3>
            <form onSubmit={handleSubmit} className='form-wrapper'>
              <div className='info-mandatory small mb-20'><i>* Campos obrigatórios</i></div>
              {form === 'short' && <FormShort />}
              {form === 'full' && <FormFull />}
              <div className='x-small mt-30'>
                <div className='input-consent'>
                  <input type='checkbox' id='appt-consent' name='appt-consent' required />
                  <span className='ml-10'>Ao inserir os dados pessoais facultativos solicitados pelo presente formulário, o utente / utilizador está a dar o seu consentimento expresso e esclarecido ao seu tratamento. Estes dados só podem ser utilizados para a finalidade específica de marcação de consultas, devendo ser eliminados assim que terminar esta finalidade ou continuarem a ser objecto de tratamento para outra finalidade ou com outro fundamento de licitude, desde que respeitem a Política de Privacidade (acessível <Link {...routes.privacy} className='reverse'><b>aqui</b></Link>). Para exercício dos demais direitos, o titular de dados pessoais deverá consultar a Política de Privacidade.</span>
                </div>
              </div>
              {formStatus > -1 &&
                <p className='ml-20 mr-20 mt-20'>
                  <b>
                    {formError && formFeedback[formStatus]}
                    {formError && <span className='block red'><br/>{formError}</span>}
                  </b>
                </p>
              }
              <button>{formStatus === 0 ? formFeedback[formStatus] : 'Marcar consulta'}</button>
            </form>
          </div>
      }
    </>
  );
};

ContactForm.propTypes = {
  bloc: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
};

ContactForm.defaultProps = {
  className: '',
};

export default ContactForm;
