import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Image, Link, Slideshow } from 'components';

const Clinic = ({ bloc, className }) => {
  const { alternative, emergency, clinic: { clinic } } = bloc;
  const { name, phone, parkingLabel, parking, address, mapLink, gallery } = clinic;
  const slidesPerPage = alternative ? 2 : 1;
  const options = alternative
    ? {
      responsive: [
        {
          breakpoint: 750,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 749,
          settings: {
            slidesToShow: 1,
          }
        },
      ],
    }
    : {};

  if (emergency) {
    return (
      <div className={cx('clinic-wrapper', [className])}>
        <div className='info-wrapper'>
          <h4 className='clinic-name'>{name}</h4>
          {phone &&
            <Link to={`tel:${phone.replace(/\s+/g, '')}`} external newTab className='clinic-phone'>
              <Image image={'icons/icon_phone.svg'} />
              {phone}
            </Link>
          }
        </div>
      </div>
    );
  }

  return (
    <div className={cx('clinic-wrapper', [className])}>
      <Slideshow slidesPerPage={slidesPerPage} options={options} className='gallery-wrapper'>
        {gallery.map((image, idx) => <Image key={idx} image={image} />)}
      </Slideshow>
      {!alternative &&
        <div className='info-wrapper'>
          <div>
            <h4 className='clinic-name'>{name}</h4>
            {phone &&
              <Link to={`tel:${phone.replace(/\s+/g, '')}`} external newTab className='clinic-phone'>
                <Image image={'icons/icon_phone.svg'} />
                <div>
                  {phone}
                  <p className='phone-notice'>CHAMADA PARA A REDE FIXA NACIONAL.</p>
                </div>
              </Link>
            }
            {(parkingLabel && parking) &&
              <Link to={parking} external newTab className='clinic-phone'>
                <Image image={'icons/icon_parking.svg'} />
                {parkingLabel}
              </Link>
            }
          </div>
          <div className='clinic-address'>
            <p dangerouslySetInnerHTML={{ __html: address }} />
            {mapLink &&
              <Link to={mapLink} external newTab className='button'>ver mapa</Link>
            }
          </div>
        </div>
      }
    </div>
  );
};

Clinic.propTypes = {
  bloc: PropTypes.object.isRequired,
  className: PropTypes.string,
};

Clinic.defaultProps = {
  className: '',
};

export default Clinic;
