import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import cx from 'classnames';

import Header from './Header';
import Footer from './Footer';
import 'assets/stylesheets/application.scss';

const Layout = ({ siteTitle, children, className }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <div className='page-wrapper'>
      <Header siteTitle={siteTitle || data.site.siteMetadata.title} />
      <div className={cx('layout-wrapper', [className])}>
        <main>{children}</main>
      </div>
      <Footer />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
};

Layout.defaultProps = {
  className: '',
};

export default Layout;
